import { Injector, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { HttpClientModule } from '@angular/common/http';
import { createCustomElement } from '@angular/elements';
import * as Highcharts from 'highcharts';
import { HighchartsChartModule } from 'highcharts-angular';
import HC_dataExporting from 'highcharts/modules/export-data';
import { TranslocoRootModule } from '../transloco/transloco-root.module';
import { AppComponent } from './app.component';
import { FlexLayoutModule } from '@angular/flex-layout';

HC_dataExporting(Highcharts);

@NgModule({
  declarations: [AppComponent],
  imports: [BrowserModule, HttpClientModule, TranslocoRootModule, HighchartsChartModule, FlexLayoutModule],
  providers: [],
  bootstrap: []
})
export class AppModule {
  constructor(injector: Injector) {
    const pdDecompositionElement = createCustomElement(AppComponent, {
      injector
    });
    customElements.define('edfx-pd-decomposition', pdDecompositionElement);
  }
  // eslint-disable-next-line @angular-eslint/use-lifecycle-interface
  ngDoBootstrap() { }
}
