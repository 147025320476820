import { Utils } from './utils';
import {
  IHistoricalPd,
  IHistoricalPdDriver,
  IPdDriverDateValuePair,
  IPdDriversChartData,
  IPdDriversData,
  ITermStructure,
  ITermStructureAnnualized,
  ITermStructureCumulative,
  ITermStructureDataWithIR,
  ITermStructureForward
} from '../models/models';
import moment from 'moment';

export abstract class ChartUtils {
  public static displayInactiveMessage(confidenceCode: string, confidenceCodes: string[], isPublic: boolean): boolean {
    if (isPublic || confidenceCode === null) {
      return false;
    }

    const extractedWarning = confidenceCode.toLowerCase().split('+')[0].split('-');

    // Checking last two entries as there are cases where the warning is not at end
    return (
      confidenceCodes.includes(extractedWarning[extractedWarning.length - 1]) ||
      confidenceCodes.includes(extractedWarning[extractedWarning.length - 2])
    );
  }

  public static convertTermStructureToPercentages(termStructure: ITermStructure) {
    if (Utils.isNullOrUndefined(termStructure) || Object.keys(termStructure).length === 0) {
      return null;
    }

    const forward: ITermStructureForward = termStructure.forward;
    const forwardData: ITermStructureDataWithIR[] = [
      {
        pd: Utils.transformToPercent(forward.forward1Y.pd),
        impliedRating: forward.forward1Y.impliedRating
      },
      {
        pd: Utils.transformToPercent(forward.forward2Y.pd),
        impliedRating: forward.forward2Y.impliedRating
      },
      {
        pd: Utils.transformToPercent(forward.forward3Y.pd),
        impliedRating: forward.forward3Y.impliedRating
      },
      {
        pd: Utils.transformToPercent(forward.forward4Y.pd),
        impliedRating: forward.forward4Y.impliedRating
      },
      {
        pd: Utils.transformToPercent(forward.forward5Y.pd),
        impliedRating: forward.forward5Y.impliedRating
      },
      {
        pd: Utils.transformToPercent(forward.forward6Y.pd),
        impliedRating: forward.forward6Y.impliedRating
      },
      {
        pd: Utils.transformToPercent(forward.forward7Y.pd),
        impliedRating: forward.forward7Y.impliedRating
      },
      {
        pd: Utils.transformToPercent(forward.forward8Y.pd),
        impliedRating: forward.forward8Y.impliedRating
      },
      {
        pd: Utils.transformToPercent(forward.forward9Y.pd),
        impliedRating: forward.forward9Y.impliedRating
      },
      {
        pd: Utils.transformToPercent(forward.forward10Y.pd),
        impliedRating: forward.forward10Y.impliedRating
      }
    ];

    const annualized: ITermStructureAnnualized = termStructure.annualized;
    const annualizedData: ITermStructureDataWithIR[] = [
      {
        pd: Utils.transformToPercent(annualized.annualized1Y.pd),
        impliedRating: annualized.annualized1Y.impliedRating
      },
      {
        pd: Utils.transformToPercent(annualized.annualized2Y.pd),
        impliedRating: annualized.annualized2Y.impliedRating
      },
      {
        pd: Utils.transformToPercent(annualized.annualized3Y.pd),
        impliedRating: annualized.annualized3Y.impliedRating
      },
      {
        pd: Utils.transformToPercent(annualized.annualized4Y.pd),
        impliedRating: annualized.annualized4Y.impliedRating
      },
      {
        pd: Utils.transformToPercent(annualized.annualized5Y.pd),
        impliedRating: annualized.annualized5Y.impliedRating
      },
      {
        pd: Utils.transformToPercent(annualized.annualized6Y.pd),
        impliedRating: annualized.annualized6Y.impliedRating
      },
      {
        pd: Utils.transformToPercent(annualized.annualized7Y.pd),
        impliedRating: annualized.annualized7Y.impliedRating
      },
      {
        pd: Utils.transformToPercent(annualized.annualized8Y.pd),
        impliedRating: annualized.annualized8Y.impliedRating
      },
      {
        pd: Utils.transformToPercent(annualized.annualized9Y.pd),
        impliedRating: annualized.annualized9Y.impliedRating
      },
      {
        pd: Utils.transformToPercent(annualized.annualized10Y.pd),
        impliedRating: annualized.annualized10Y.impliedRating
      }
    ];

    const cumulative: ITermStructureCumulative = termStructure.cumulative;
    const cumulativeData: ITermStructureDataWithIR[] = [
      {
        pd: Utils.transformToPercent(cumulative.cumulative1Y.pd),
        impliedRating: cumulative.cumulative1Y.impliedRating
      },
      {
        pd: Utils.transformToPercent(cumulative.cumulative2Y.pd),
        impliedRating: cumulative.cumulative2Y.impliedRating
      },
      {
        pd: Utils.transformToPercent(cumulative.cumulative3Y.pd),
        impliedRating: cumulative.cumulative3Y.impliedRating
      },
      {
        pd: Utils.transformToPercent(cumulative.cumulative4Y.pd),
        impliedRating: cumulative.cumulative4Y.impliedRating
      },
      {
        pd: Utils.transformToPercent(cumulative.cumulative5Y.pd),
        impliedRating: cumulative.cumulative5Y.impliedRating
      },
      {
        pd: Utils.transformToPercent(cumulative.cumulative6Y.pd),
        impliedRating: cumulative.cumulative6Y.impliedRating
      },
      {
        pd: Utils.transformToPercent(cumulative.cumulative7Y.pd),
        impliedRating: cumulative.cumulative7Y.impliedRating
      },
      {
        pd: Utils.transformToPercent(cumulative.cumulative8Y.pd),
        impliedRating: cumulative.cumulative8Y.impliedRating
      },
      {
        pd: Utils.transformToPercent(cumulative.cumulative9Y.pd),
        impliedRating: cumulative.cumulative9Y.impliedRating
      },
      {
        pd: Utils.transformToPercent(cumulative.cumulative10Y.pd),
        impliedRating: cumulative.cumulative10Y.impliedRating
      }
    ];

    return {
      forward: forwardData,
      annualized: annualizedData,
      cumulative: cumulativeData
    };
  }

  public static filterPDDrivers(startDate: string, endDate: string, fso: boolean, pdDrivers: IPdDriversData[]) {
    const driversData = pdDrivers.filter(
      data =>
        moment(startDate).isSameOrAfter(data.inputs.startDate) &&
        moment(endDate).isSameOrBefore(data.inputs.endDate) &&
        data.inputs.fso === fso
    );
    return this.buildPDDriversChartData(driversData[0]);
  }

  public static buildPDDriversChartData = (driversData: IPdDriversData) => {
    if (!driversData || Utils.isNullOrUndefined(driversData.data)) {
      return null;
    }

    const drivers: IPdDriversChartData = {
      pds: this.convertPDsToPercents(driversData.data?.pds),
      contributions: this.convertDriversToPercents(driversData.data?.contributions),
      ratios: driversData.data?.ratios,
      percentiles: this.convertDriversToPercents(driversData.data?.percentiles),
      assetVolatilities: this.convertDriversToPointNumbers(driversData.data?.assetVolatilities),
      marketLeverages: this.convertDriversToPointNumbers(driversData.data?.marketLeverages),
      marketValueOfAssets: this.convertDriversToPointNumbers(driversData.data?.marketValueOfAssets),
      defaultPoints: this.convertDriversToPointNumbers(driversData.data?.defaultPoints),
      companyPds: this.convertPDToPointNumbers(this.convertPDsToPercents(driversData.data?.pds)),
      startDate: driversData.inputs?.startDate,
      endDate: driversData.inputs?.endDate,
      entityId: driversData.inputs.entityId,
      frequency: driversData.inputs.frequency,
      fso: driversData.inputs.fso,
      edfDrivers: driversData.data?.edfDrivers,
      termStructure: ChartUtils.convertTermStructureToPercentages(driversData.data?.termStructure)
    };

    return drivers;
  };

  public static convertPDToPointNumbers(pds: IHistoricalPd[]) {
    return pds?.map(ir => ({ value: ir.pd, date: ir.date }));
  }

  public static convertDriversToPointNumbers(drivers: IPdDriverDateValuePair[]) {
    return drivers?.map(ir => ({
      value: ir.value,
      date: ir.date
    }));
  }

  public static convertPDsToPercents(pds: IHistoricalPd[]) {
    return pds?.map(pd => ({
      ...pd,
      pd: pd?.pd && !isNaN(pd.pd) ? pd?.pd * 100 : pd?.pd
    }));
  }

  public static convertDriversToPercents(drivers: IHistoricalPdDriver[]) {
    return drivers?.map(driver => ({
      ...driver,
      drivers: driver?.drivers?.map(pdDriver => ({
        ...pdDriver,
        value: pdDriver?.value && !isNaN(pdDriver?.value) ? pdDriver?.value * 100 : pdDriver?.value
      }))
    }));
  }
}
