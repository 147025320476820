<div class="container" *ngIf="hasEntitlement && !loadingChart && !invalidEntityId && !isBenchmarkModel && !isPublicModel">
  <div class="card-title">{{'CARD_TITLE' | transloco}}</div>
  <div class="card-description">{{'CARD_DESCRIPTION' | transloco}}</div>
  <ng-container *ngIf="!apiError && !isBenchmarkModel && !isPublicModel && !invalidEntityId">
    <highcharts-chart (chartInstance)="setChart($event)" [(update)]="updateFlag" [Highcharts]="highcharts"
      [options]="options" [style.margin-top]="'25px'" [style.width]="'100%'" [style.height]="'100%'"
      style="display: block"></highcharts-chart>
  </ng-container>
</div>
<div fxLayout="row" fxLayoutAlign="space-around center">
  <div class="spinner" *ngIf="loadingChart && hasEntitlement"></div>
</div>
<ng-container *ngIf="!loadingChart && !hasEntitlement && !apiError">
  <p>{{"ENTITLEMENT_ERROR" | transloco}}</p>
</ng-container>
<ng-container *ngIf="!loadingChart && apiError">
  <p class="token-invalid-message">{{"TOKEN_INVALID"| transloco}}</p>
</ng-container>
<ng-container *ngIf="hasEntitlement && (isBenchmarkModel || isPublicModel) && !invalidEntityId">
  <p class="token-invalid-message">{{"MODEL_UNAVAILABLE_ERROR"| transloco}}</p>
</ng-container>
<ng-container *ngIf="hasEntitlement && !loadingChart && invalidEntityId">
  <p class="token-invalid-message">Entity id {{entityId}} does not exists.</p>
</ng-container>