import { TranslocoService } from '@ngneat/transloco';
import { IPdDriverWithColor } from '../models/company-data.interface';
import { EdfxColors } from '../utils/edfx-style';

export interface IDriversSummaryChartData {
  series?: Array<Highcharts.SeriesOptionsType>;
  categories?: string[];
}

export class EdfxDriversSummaryComponentDataBuilder {
  public static buildChartData(data: IPdDriverWithColor[], localizationData: any): IDriversSummaryChartData {
    const { series, categories } = this.buildColumns(data);

    // for increase, we fill only when the difference between the next and previous is positive
    series.push(
      EdfxDriversSummaryComponentDataBuilder.addEdfSeriesValues(
        EdfxColors.red, // $data-viz-red
        [],
        localizationData.COMPANY.ONE_YEAR_CHANGE.INCREASE
      )
    );

    series.push(
      EdfxDriversSummaryComponentDataBuilder.addEdfSeriesValues(
        EdfxColors.green, // $data-viz-green
        [],
        localizationData.COMPANY.ONE_YEAR_CHANGE.DECREASE
      )
    );

    return { series, categories };
  }

  private static buildColumns(data: IPdDriverWithColor[]): IDriversSummaryChartData {
    const series: Array<Highcharts.SeriesOptionsType> = [];

    // its corresponding to number of values by series
    const numberOfColumns = data.length;

    const categories: string[] = [];
    // A running total of the distances between the pd drivers.
    // Ex: driver1.value = 0.01 and driver2.value = 0.3 so gaps[0] = 0.31
    const gaps: number[] = [];

    // The transparent columns under the visible values that act as spacers
    const transparentSpacerValues: number[] = [];
    let currentGap = 0;

    data.forEach((driver, index) => {
      // The first driver, even if it is grounded, needs to set the initial values for currentGap, gaps, and transparentSpacerValues
      if (!driver.grounded || index === 0) {
        const gap = parseFloat(currentGap.toString());
        const isOpposite = index > 0 ? Math.sign(driver.value * currentGap) : 1;
        currentGap += driver.value;
        const gapToReturn = gap + (isOpposite === -1 ? driver.value : 0);
        transparentSpacerValues.push(gapToReturn);
        gaps.push(currentGap);
      } else {
        transparentSpacerValues.push(0);
        gaps.push(currentGap);
      }

      const color = driver.color;
      const columnValues = new Array(numberOfColumns).fill(0);

      const previousGapValue = index > 0 ? gaps[index - 1] : 0;

      // Produces 1, 0, or -1 depending upon if the transition between driver values was positive, neutral, or negative
      const rawSignOfTransition = Math.sign(driver.value) * Math.sign(previousGapValue);
      // The sign of the transition between driver values. Produces a 1 if the transition was positive and a -1 if the
      // transition was negative. Neutral transitions from rawSignOfTransition are converted it to a positive transition.
      const signOfTransition = rawSignOfTransition === 0 ? 1 : rawSignOfTransition;

      const signChange = signOfTransition === -1 && Math.sign(previousGapValue) * Math.sign(gaps[index]) === -1;
      if (signChange) {
        const firstPartColumnValue = new Array(numberOfColumns).fill(0);
        firstPartColumnValue[index] = previousGapValue;
        series.push(EdfxDriversSummaryComponentDataBuilder.addEdfSeriesValues(color, firstPartColumnValue, driver.name, false));

        const secondPartColumnValue = new Array(numberOfColumns).fill(0);
        secondPartColumnValue[index] = gaps[index];
        series.push(EdfxDriversSummaryComponentDataBuilder.addEdfSeriesValues(color, secondPartColumnValue, driver.name, false));

        transparentSpacerValues[index] = 0;
      } else {
        columnValues[index] = signOfTransition * driver.value;
        series.push(EdfxDriversSummaryComponentDataBuilder.addEdfSeriesValues(color, columnValues, driver.name, false));
      }
      categories.push(driver.name);

      if (index !== data.length - 1) {
        series.push(EdfxDriversSummaryComponentDataBuilder.addLine(index + 0.08, index + 0.92, gaps[index]) as any);
      }

      if (driver.value === 0) {
        series.push(EdfxDriversSummaryComponentDataBuilder.addLine(index - 0.08, index + 0.08, gaps[index], 'Solid') as any);
      }
    });

    series.push(EdfxDriversSummaryComponentDataBuilder.addEdfSeriesValues('transparent', transparentSpacerValues as any, 'fake', false));

    return { series, categories };
  }

  /**
   *
   * @param color: grey -> previous PD values, green -> current PD value, red -> increase columns, green -> decrease
   *   columns
   * @param values: point values
   * @param showInLegend: show in legend
   * @param name the serie name
   * @private
   */
  public static addEdfSeriesValues(color: string, values: number[], name: string, showInLegend: boolean = true): any {
    return {
      type: 'column',
      enableMouseTracking: false,
      color,
      name,
      showInLegend,
      data: values as any
    };
  }

  public static addLine(fromX: number, toX: number, staticY: number, dashStyle: string = 'ShortDash') {
    return {
      type: 'line',
      color: EdfxColors.gray,
      showInLegend: false,
      enableMouseTracking: false,
      dashStyle,
      data: [
        {
          x: fromX, // define x values for points explicitly
          y: staticY
        },
        {
          x: toX,
          y: staticY
        }
      ] as any,
      marker: {
        enabled: false
      },

      tooltip: {
        enabled: false
      }
    };
  }
}
