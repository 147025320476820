import { formatDate } from '@angular/common';
import { Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges, ViewEncapsulation } from '@angular/core';
import { TranslocoService } from '@ngneat/transloco';
import * as Highcharts from 'highcharts';
import { Subject, catchError, combineLatest, of, switchMap, takeUntil } from 'rxjs';
import { Entitlements, FREQUENCY_MONTHLY, MOMENT_FORMATS } from '../../interfaces/constants';
import {
  ICompanyModel,
  ICompanyProfile,
  IEntitySearchResponse,
  INSOLVENT_BANKRUPT,
  IPDHistoricalInputPayload,
  IPdDriverWithColor,
  IPdDriversChartData,
  ISmartCardData,
  ModelId,
  ParentGroupSupportType
} from '../models/models';
import { ChartUtils } from '../utils/chart.utils';
import { DateUtils } from '../utils/date.utils';
import { Utils } from '../utils/utils';
import { EdfxDriversSummaryComponentDataBuilder } from './edfx-drivers-summary.component.data-builder';
import { IEntitlement } from './interfaces/entitlement.interface';
import { ApiService } from './services/api.service';
import { DriversChartDataBuilderService } from './services/drivers-chart-data-builder.service';
import * as translocoData from '../assets/i18n/en.pdDecomposition.json';
import { ModelNameService } from './services/model-name.service';

@Component({
  selector: 'edfx-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  encapsulation: ViewEncapsulation.ShadowDom
})
export class AppComponent implements OnInit, OnChanges, OnDestroy {
  private readonly destroy$ = new Subject<void>();
  today = formatDate(new Date(), 'yyyy-MM-dd', 'en-US');
  loadingChart = true;
  private seriesData: IPdDriverWithColor[];
  public defaultOptions: Highcharts.Options = {
    title: {
      text: ''
    },
    chart: {
      type: 'column',
      events: {
        load() {
          this.showLoading();
        }
      }
    },
    exporting: {
      tableCaption: false,
      buttons: {
        contextButton: {
          enabled: false
        }
      },
      csv: {
        columnHeaderFormatter: (item: any) => {
          if (!item) {
            return '';
          }

          if (item instanceof Highcharts.Axis) {
            return 'PD Drivers';
          }

          return item.name;
        }
      }
    },
    yAxis: {
      title: undefined,
      stackLabels: {
        enabled: true,
        style: {
          // theme
          color: 'gray',
          textOutline: '0px'
        },
        formatter: this.stackLabelsFormatter()
      },
      labels: {
        format: '{value}%'
      },
      plotLines: [],
      softMax: 0.01
    },
    xAxis: {
      tickLength: 0,
      labels: {
        style: {
          textOverflow: 'allow'
        }
      }
    },
    plotOptions: {
      column: {
        stacking: 'normal',
        centerInCategory: true,
        groupPadding: 0.1,
        pointPadding: 0,
        pointWidth: 50
      },
      series: {
        borderWidth: 0
      }
    },
    tooltip: {
      headerFormat: ' <span style="color:{series.color}">{point.key}</span>: {point.y}%<br/>',
      valueDecimals: 2,
      pointFormat: '',
      footerFormat: ''
    },
    credits: {
      enabled: false
    }
  };
  public options = this.defaultOptions;
  chart: Highcharts.Chart = null;
  highcharts: typeof Highcharts = Highcharts;
  asOfDate: string;
  updateFlag = true;
  companyModel: ICompanyModel;
  companyName: string;
  entityId: string;
  isFinancialModel: boolean;
  displayInactiveMessage: boolean;
  private inputs: IPDHistoricalInputPayload;
  private pdDriversChartData: IPdDriversChartData;
  apiError = false;
  hasEntitlement = false;
  isBenchmarkModel = false;
  isPublicModel = false;
  invalidEntityId = false;
  pdDecompositionData: ISmartCardData;
  localizationData: any;
  public isPeerDrivenEntity = false;

  @Input() token!: string;
  @Input() inputData!: string | ISmartCardData;

  constructor(
    private translocoService: TranslocoService,
    private apiService: ApiService,
    private driversChartDataBuilderService: DriversChartDataBuilderService,
    private modelNameService: ModelNameService
  ) {
    // Only for dev testing, comment out for building the web element!
    // this.pdDecompositionData = { entityId: 'GB11297107' };
    // this.token =
    //   // eslint-disable-next-line max-len
    //   '';
    // this.inputData = JSON.stringify(this.pdDecompositionData);
  }

  ngOnInit(): void {
    this.localizationData = JSON.parse(JSON.stringify(translocoData));
    if (!Utils.isNullOrUndefined(this.inputData) && !Utils.isNullOrUndefined(this.token)) {
      this.initializeComponent();
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['inputData'] && !Utils.isNullOrUndefined(changes['inputData'].currentValue)) {
      this.inputData = changes['inputData'].currentValue;
      this.resetComponent();
    }
    if (changes['token'] && !Utils.isNullOrUndefined(changes['token'].currentValue)) {
      this.token = changes['token'].currentValue;
      this.resetComponent();
    }
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  getPdDecompositionDataDataFromInput() {
    if (typeof this.inputData === 'string') {
      this.pdDecompositionData = JSON.parse(this.inputData) as ISmartCardData;
    } else if (typeof this.inputData === 'object') {
      this.pdDecompositionData = this.inputData;
    }
  }

  resetComponent() {
    this.apiError = false;
    this.isBenchmarkModel = false;
    this.isPublicModel = false;
    this.isFinancialModel = false;
    this.displayInactiveMessage = false;
    this.isPeerDrivenEntity = false;
    this.hasEntitlement = false;
    this.chart = null;
    this.invalidEntityId = false;
    this.options = this.defaultOptions;
    this.initializeComponent();
  }

  private initializeComponent() {
    this.getPdDecompositionDataDataFromInput();
    const entitlement_list = [
      Entitlements.MIR_MEDIAN_CREDIT_SPREAD,
      Entitlements.ORBIS_ALL_COMPANIES,
      Entitlements.PRIVATE_ENTITLEMENT,
      Entitlements.PUBLIC_ENTITLEMENT,
      Entitlements.PUBLIC_PLUS_ENTITLEMENT
    ];
    this.loadingChart = true;
    this.showOrHideSpinner();
    this.apiService.setToken(this.token);
    this.asOfDate = !Utils.isNullOrUndefined(this.pdDecompositionData?.asOfDate) ? this.pdDecompositionData?.asOfDate : this.today;
    this.entityId = this.pdDecompositionData?.entityId;
    // Load translations before making the API call for entitlements
    this.translocoService
      .load('en')
      .pipe(
        takeUntil(this.destroy$),
        catchError(error => {
          // Handle error from loading translations
          this.apiError = true;
          this.loadingChart = false;
          return of(); // Continue the stream even if there's an error
        }),
        switchMap(() =>
          this.apiService.getEntitlements(entitlement_list).pipe(
            takeUntil(this.destroy$),
            catchError(error => {
              if (error.status === 401 || error.status === 0) {
                this.apiError = true;
                this.loadingChart = false;
              }
              this.hasEntitlement = false;
              return of();
            })
          )
        )
      )
      .subscribe(entitlements => {
        // Handle the received entitlements
        this.onEntitlementsReceived(entitlements);
      });
  }

  onEntitlementsReceived(entitlements: IEntitlement[]) {
    this.hasEntitlement = entitlements?.some(
      entitlement => entitlement.name === Entitlements.PRIVATE_ENTITLEMENT || entitlement.name === Entitlements.PUBLIC_ENTITLEMENT
    );
    if (!this.hasEntitlement) {
      this.loadingChart = false;
      this.apiError = false;
      return;
    }
    this.getEntityId();
  }

  getEntityId(): void {
    const currentEntityId = this.entityId;
    this.apiService
      .getEntityId(currentEntityId, false)
      .pipe(
        takeUntil(this.destroy$),
        catchError(error => {
          if (error.status === 401 || error.status === 0) {
            this.apiError = true;
          }
          this.loadingChart = false;
          return of();
        })
      )
      .subscribe((entityIdResult: IEntitySearchResponse) => {
        if (Utils.isNullOrUndefined(entityIdResult)) {
          this.invalidEntityId = true;
          this.loadingChart = false;
          return;
        }
        if (entityIdResult.entities.length === 0) {
          this.getCustomEntityId(currentEntityId);
          return;
        }

        this.entityId = entityIdResult?.entities?.length > 0 ? entityIdResult.entities[0]?.entityId : this.entityId;
        this.onEntityIdReceived();
      });
  }

  getCustomEntityId(currentEntityId: string) {
    this.apiService
      .getEntityId(currentEntityId, true)
      .pipe(
        takeUntil(this.destroy$),
        catchError(error => {
          if (error.status === 401 || error.status === 0) {
            this.apiError = true;
          }
          this.loadingChart = false;
          return of();
        })
      )
      .subscribe((entityIdResult: IEntitySearchResponse) => {
        if (Utils.isNullOrUndefined(entityIdResult)) {
          this.invalidEntityId = true;
          this.loadingChart = false;
          return;
        }

        if (entityIdResult.entities.length === 0) {
          this.invalidEntityId = true;
          this.loadingChart = false;
          return;
        }
        this.entityId = entityIdResult?.entities?.length > 0 ? entityIdResult.entities[0]?.entityId : this.entityId;
        this.onEntityIdReceived();
      });
  }

  onEntityIdReceived() {
    combineLatest([this.apiService.getModel(this.entityId, this.asOfDate), this.apiService.getCompanyData(this.entityId, this.asOfDate)])
      .pipe(
        takeUntil(this.destroy$),
        catchError(err => {
          this.loadingChart = false;
          this.showOrHideSpinner();
          this.apiError = true;
          return of(err);
        })
      )
      .subscribe(([model, profile]) => {
        this.onEntityInfoReceived(model, profile);
      });
  }

  private onEntityInfoReceived(model: ICompanyModel, profile: ICompanyProfile): void {
    if (!model || !profile) {
      return;
    }

    this.companyModel = model;
    this.companyName = profile.name || '';

    const modelId = this.companyModel.modelId;
    const primaryModelId = modelId?.split('+')[0];
    if (!primaryModelId) {
      this.loadingChart = false;
      this.showOrHideSpinner();
      return;
    }
    this.isBenchmarkModel = primaryModelId.toLowerCase().includes(ModelId.BENCHMARK);
    this.isPublicModel = primaryModelId.toLowerCase().includes(ModelId.EDF9);
    const isPaymentModel = primaryModelId.toLowerCase().includes(ModelId.PAYMENT);
    this.isFinancialModel = !this.isBenchmarkModel && !isPaymentModel;
    this.displayInactiveMessage = ChartUtils.displayInactiveMessage(model.confidenceIndicator, INSOLVENT_BANKRUPT, this.isPublicModel);
    this.isPeerDrivenEntity = this.modelNameService.getPeerDrivenEntityFromConfidenceIndicator(model);

    if (this.isPublicModel || this.isPeerDrivenEntity) {
      this.loadingChart = false;
      this.showOrHideSpinner();
      return;
    }
    else if (this.isBenchmarkModel && !this.isPeerDrivenEntity) {
      this.initBenchmarkModel();
    }
    else {
      this.initPrivateModel();
    }
  }

  private initBenchmarkModel() {
    const latestKnownDate = DateUtils.getLatestDate(this.companyModel.latestKnownPdDate);
    const inputEndDate = latestKnownDate.format(MOMENT_FORMATS.payload);
    const inputStartDate = latestKnownDate.subtract(2, 'y').format(MOMENT_FORMATS.payload);

    this.inputs = {
      entityId: this.companyModel.id,
      endDate: inputEndDate,
      startDate: inputStartDate,
      frequency: FREQUENCY_MONTHLY,
      fso: true
    };
    this.getBenchmarkPdHistoricalData();
  }

  private initPrivateModel() {
    const numberOfYears = this.isFinancialModel ? 5 : 4;
    const latestKnownDate = DateUtils.getLatestDate(this.companyModel.latestKnownPdDate);
    const inputEndDate = latestKnownDate.format(MOMENT_FORMATS.payload);
    const inputStartDate = latestKnownDate.subtract(numberOfYears, 'y').format(MOMENT_FORMATS.payload);

    this.inputs = {
      entityId: this.companyModel.id,
      endDate: inputEndDate,
      startDate: inputStartDate,
      frequency: FREQUENCY_MONTHLY,
      fso: true
    };
    this.getPrivatePdHistoricalData();
  }

  getBenchmarkPdHistoricalData(): void {
    this.apiService
      .getMonthlyHistoricalPdDrivers(this.inputs)
      .pipe(
        takeUntil(this.destroy$),
        catchError(err => {
          this.loadingChart = false;
          this.showOrHideSpinner();
          this.apiError = true;
          return of(err);
        })
      )
      .subscribe(e => {
        this.pdHistoricalBenchmarkDriversReceived(
          ChartUtils.filterPDDrivers(this.inputs.startDate, this.inputs.endDate, this.inputs.fso, [
            {
              data: e,
              loading: false,
              error: '',
              inputs: this.inputs
            }
          ])
        );
      });
  }

  getPrivatePdHistoricalData(): void {
    this.apiService
      .getMonthlyHistoricalPdDrivers(this.inputs)
      .pipe(
        takeUntil(this.destroy$),
        catchError(err => {
          this.loadingChart = false;
          this.showOrHideSpinner();
          this.apiError = true;
          return of(err);
        })
      )
      .subscribe(e => {
        this.pdHistoricalPrivateDriversReceived(
          ChartUtils.filterPDDrivers(this.inputs.startDate, this.inputs.endDate, this.inputs.fso, [
            {
              data: e,
              loading: false,
              error: '',
              inputs: this.inputs
            }
          ])
        );
      });
  }

  private pdHistoricalBenchmarkDriversReceived(historicalPdDrivers: IPdDriversChartData): void {
    if (!historicalPdDrivers || !this.companyModel) {
      this.loadingChart = false;
      this.showOrHideSpinner();
      return;
    }
    this.pdDriversChartData = historicalPdDrivers;

    this.pdDriversChartData.lastKnownPd =
      this.companyModel?.latestKnownPdValue && !isNaN(this.companyModel.latestKnownPdValue)
        ? this.companyModel?.latestKnownPdValue * 100
        : this.companyModel?.latestKnownPdValue;
    this.pdDriversChartData.lastKnownPdDate = this.companyModel.latestKnownPdDate;

    this.pdDriversChartData.quantitativePd =
      this.companyModel?.latestKnownQuantitativePd && !isNaN(this.companyModel.latestKnownQuantitativePd)
        ? this.companyModel.latestKnownQuantitativePd * 100
        : this.companyModel?.latestKnownQuantitativePd;

    this.pdDriversChartData.qualitativeOverlayPd =
      this.companyModel?.pdWithQualitativeOverlay && !isNaN(this.companyModel.pdWithQualitativeOverlay)
        ? this.companyModel.pdWithQualitativeOverlay * 100
        : this.companyModel.pdWithQualitativeOverlay;

    this.seriesData = this.driversChartDataBuilderService.buildDriverSummaryDataForBenchmark(this.pdDriversChartData);
    this.refreshChart();
  }

  private pdHistoricalPrivateDriversReceived(historicalPdDrivers: IPdDriversChartData): void {
    if (!historicalPdDrivers || !this.companyModel) {
      this.loadingChart = false;
      this.showOrHideSpinner();
      return;
    }
    this.pdDriversChartData = historicalPdDrivers;

    this.pdDriversChartData.lastKnownPd =
      this.companyModel?.latestKnownPdValue && !isNaN(this.companyModel.latestKnownPdValue)
        ? this.companyModel?.latestKnownPdValue * 100
        : this.companyModel?.latestKnownPdValue;
    this.pdDriversChartData.lastKnownPdDate = this.companyModel.latestKnownPdDate;

    this.pdDriversChartData.quantitativePd =
      this.companyModel?.latestKnownQuantitativePd && !isNaN(this.companyModel.latestKnownQuantitativePd)
        ? this.companyModel.latestKnownQuantitativePd * 100
        : this.companyModel?.latestKnownQuantitativePd;

    this.pdDriversChartData.qualitativeOverlayPd =
      this.companyModel?.pdWithQualitativeOverlay && !isNaN(this.companyModel.pdWithQualitativeOverlay)
        ? this.companyModel.pdWithQualitativeOverlay * 100
        : this.companyModel.pdWithQualitativeOverlay;

    this.pdDriversChartData.pgs = this.companyModel?.confidenceIndicator.includes(ParentGroupSupportType.BASE);
    this.seriesData = this.isFinancialModel
      ? this.driversChartDataBuilderService.buildTtcPdChangeForFinancial(this.pdDriversChartData)
      : this.driversChartDataBuilderService.buildTtcPdDecompositionForPayment(this.pdDriversChartData);
    this.refreshChart();
  }

  public hasData(): boolean {
    return Array.isArray(this.seriesData) && this.seriesData.length > 0;
  }

  private refreshChart() {
    if (!this.hasData()) {
      this.loadingChart = false;
      this.showOrHideSpinner();
      return;
    }

    if (this.chart?.series) {
      while (this.chart?.series.length > 0) {
        this.chart.series[0].remove(true);
      }
    }
    const { series, categories } = EdfxDriversSummaryComponentDataBuilder.buildChartData(this.seriesData, this.localizationData);
    series.forEach(singleSeries => {
      this.chart?.addSeries(singleSeries);
    });

    this.chart?.update({ xAxis: { categories } });
    this.chart?.reflow();
    this.loadingChart = false;
    this.showOrHideSpinner();
  }

  private stackLabelsFormatter() {
    const $this = this;
    return function (this: Highcharts.StackItemObject): string {
      // eslint-disable-next-line no-debugger
      if (this.total >= 0 && this.x <= $this.seriesData.length) {
        const label =
          this.x === $this.seriesData.length ? $this.seriesData.map(v => v.value).reduce((a, b) => a + b) : $this.seriesData[this.x].value;
        if (label && typeof label === 'number' && !isNaN(label)) {
          return label.toFixed(2) + '%';
        }
      }
      return '';
    };
  }

  public setChart(chart: Highcharts.Chart): void {
    this.chart = chart;
    if (this.chart) {
      this.refreshChart();
    }
    this.showOrHideSpinner();
  }

  showOrHideSpinner() {
    if (this.loadingChart) {
      this.chart?.showLoading('Loading ...');
    } else {
      this.chart?.hideLoading();
    }
  }
}
