import { Injectable } from '@angular/core';
import { ICompanyModel, IConfidenceCode, Warning, ModelType, DataAge, DataQuality, ParentType, ParentGroupSupportType } from '../../models/models';
import { Utils } from '../../utils/utils';

@Injectable({
  providedIn: 'root'
})
export class ModelNameService {
  constructor() { }

  public parseConfidenceCode(confidenceIndicator: string): IConfidenceCode {
    const parts = Utils.isNullOrUndefined(confidenceIndicator) ? [] : confidenceIndicator.split('-');
    const warnings: Warning[] = [];
    const modelType = parts.length > 0 ? (parts[0] as ModelType) : ModelType.UNKNOWN;
    const dataQuality = parts.length > 1 ? (parts[1] as DataQuality) : DataQuality.UNKNOWN;
    const dataAge = parts.length > 2 ? (parts[2] as DataAge) : DataAge.UNKNOWN;

    let parentType = ParentType.UNKNOWN;
    let parentGroupSupportType = ParentGroupSupportType.UNKNOWN;

    // Handle remaining parts, warn of unknown codes
    for (let i = 3; i < parts.length; ++i) {
      switch (parts[i]) {
        case ParentType.DOMESTIC_ULTIMATE_OWNER:
        case ParentType.HEADQUARTERS:
          parentType = parts[i] as ParentType;
          break;
        case ParentGroupSupportType.BASE:
          parentGroupSupportType = parts[i] as ParentGroupSupportType;
          break;
        case ParentGroupSupportType.EXPLICIT:
        case ParentGroupSupportType.IMPLICIT:
        case ParentGroupSupportType.ADVERSE_IMPACT:
          // Check that BASE was set prior
          if (parentGroupSupportType === ParentGroupSupportType.BASE) {
            parentGroupSupportType = parts[i] as ParentGroupSupportType;
          } else {
            warnings.push(parts[i] as Warning);
          }
          break;
        default:
          warnings.push(parts[i] as Warning);
      }
    }

    return { modelType, dataQuality, dataAge, warnings, parentType, parentGroupSupportType };
  }
  public getPeerDrivenEntityFromConfidenceIndicator(companyModel: ICompanyModel): boolean {
    const confidenceCode = this.parseConfidenceCode(companyModel.confidenceIndicator);
    return confidenceCode.modelType === ModelType.BENCHMARK_MODEL && confidenceCode.dataQuality === DataQuality.PEER_DRIVEN_BENCHMARK;
  }
}
