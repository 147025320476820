import { Injectable } from '@angular/core';
import { TranslocoService } from '@ngneat/transloco';
import { MOMENT_FORMATS } from 'interfaces/constants';
import moment from 'moment';
import { Subject } from 'rxjs';
import { IEdfDrivers, IHistoricalPd, IPdDriversChartData, IPdDriverWithColor } from '../../models/company-data.interface';
import { EdfxColors } from '../../utils/edfx-style';
import { Utils } from '../../utils/utils';

@Injectable({
  providedIn: 'root'
})
export class DriversChartDataBuilderService {
  public getEWSScenarioDataEvent$ = new Subject<string[]>();

  constructor(private translocoService: TranslocoService) {}

  // Used for benchmark model
  public buildDriverSummaryDataForBenchmark(driversData: IPdDriversChartData) {
    const hasPds = !!driversData?.pds && driversData.pds.length > 0;
    const driversDataLatestPd: IHistoricalPd = Utils.findLatestObjByDate(driversData.pds);
    const benchmarkPd = hasPds && driversDataLatestPd ? driversDataLatestPd.pd : 0;
    const lastKnownPd = driversData?.lastKnownPd;
    const pdDifference =
      !!driversData.quantitativePd && benchmarkPd
        ? driversData.quantitativePd - benchmarkPd
        : lastKnownPd && benchmarkPd
        ? lastKnownPd - benchmarkPd
        : 0;

    const driverSeries: IPdDriverWithColor[] = [
      {
        name: 'Benchmark',
        value: benchmarkPd,
        color: EdfxColors.lightGray4,
        grounded: true
      },
      {
        name: 'Credit Cycle Adjustment',
        value: pdDifference,
        color: pdDifference < 0 ? EdfxColors.green : EdfxColors.red
      }
    ];

    if (!!driversData.quantitativePd) {
      const overlayDifference = lastKnownPd - driversData.quantitativePd;
      driverSeries.push({
        name: 'Payment Behavior Change',
        value: driversData.quantitativePd,
        color: EdfxColors.lightGray4,
        grounded: true
      });
      driverSeries.push({
        name: 'Overlay',
        value: overlayDifference,
        color: overlayDifference < 0 ? EdfxColors.green : EdfxColors.red
      });
      driverSeries.push({
        name: 'Final PD',
        value: lastKnownPd,
        color: EdfxColors.lightGray4,
        grounded: true
      });
    } else {
      driverSeries.push({
        name: 'Final Pit PD',
        value: lastKnownPd,
        color: EdfxColors.lightGray4,
        grounded: true
      });
    }

    return driverSeries;
  }

  /**
   * Build the TTC (Through the Cycle) PD Decomposition for a Private (Payment Model) company.
   *
   * @param driversData
   */
  public buildTtcPdDecompositionForPayment(driversData: IPdDriversChartData): IPdDriverWithColor[] {
    const hasPds = Array.isArray(driversData?.pds) && driversData.pds.length > 0;
    const pdsCount = hasPds ? driversData.pds.length : 0;
    const ttcPdLabel = 'TTC PD';
    const pitAdjustmentLabel = 'Point In Time Adjustment';

    const driversDataLatestPd: IHistoricalPd = Utils.findLatestObjByDate(driversData.pds);
    const driversDataNextLatestPd: IHistoricalPd = Utils.findLatestObjByDate(driversData.pds.filter(pd => pd !== driversDataLatestPd));
    const fsoPd2 = hasPds && driversDataLatestPd ? driversDataLatestPd : null;
    const fsoPd2Label =
      hasPds && fsoPd2 ? `${ttcPdLabel} (${moment(fsoPd2.date).format(MOMENT_FORMATS.display.monthYearLabel)})` : ttcPdLabel;
    const driverSeries: IPdDriverWithColor[] = [];

    // Build the driver data columns for the first "TTC PD" and "Financial Statement Change" difference
    if (pdsCount > 1) {
      const fsoPd1 = driversDataNextLatestPd;
      const pd1Label = `${ttcPdLabel} (${moment(fsoPd1.date).format(MOMENT_FORMATS.display.monthYearLabel)})`;
      const change = fsoPd2.pd - fsoPd1.pd;
      const label2 = 'Payment Behavior Change';

      driverSeries.push({
        name: pd1Label,
        value: fsoPd1.pd,
        color: EdfxColors.lightGray4,
        grounded: true
      });
      driverSeries.push({
        name: label2,
        value: change,
        color: change < 0 ? EdfxColors.green : EdfxColors.red
      });
    }

    // Build the driver data columns for: Second TTC PD and Point in Time Adjustment
    const lastKnownPd = driversData?.lastKnownPd;
    const adjustment =
      !!driversData.quantitativePd && fsoPd2 ? driversData.quantitativePd - fsoPd2.pd : lastKnownPd && fsoPd2 ? lastKnownPd - fsoPd2.pd : 0;

    driverSeries.push({
      name: fsoPd2Label,
      value: fsoPd2.pd,
      color: EdfxColors.lightGray4,
      grounded: true
    });
    driverSeries.push({
      name: pitAdjustmentLabel,
      value: adjustment,
      color: adjustment < 0 ? EdfxColors.green : EdfxColors.red
    });

    // Render remaining drivers
    driverSeries.push(...this.buildQuantitativeDiffAndFinalPDDrivers(driversData));

    return driverSeries;
  }

  /**
   * Build the TTC (Through the Cycle) PD Change for a Private (Financial Model) company.
   *
   * @param driversData - company data used to render the chart driversSeries
   */
  public buildTtcPdChangeForFinancial(driversData: IPdDriversChartData) {
    const hasPds = Array.isArray(driversData?.pds) && driversData.pds.length > 0;
    const pdsCount = hasPds ? driversData.pds.length : 0;
    const ttcPdLabel = 'TTC PD';
    const pitAdjustmentLabel = 'Point In Time Adjustment';
    const hasEdfDrivers = Array.isArray(driversData?.edfDrivers) && driversData.edfDrivers.length > 0;
    const edfDriversCount = hasEdfDrivers ? driversData.edfDrivers.length : 0;
    const driversDataLatestEdfDriver: Partial<IEdfDrivers> = Utils.findLatestObjByDate(driversData.edfDrivers);
    const driversDataNextLatestEdfDriver: Partial<IEdfDrivers> = Utils.findLatestObjByDate(
      driversData.edfDrivers.filter(edfDriver => edfDriver !== driversDataLatestEdfDriver)
    );
    const edfDriver2Moment =
      hasEdfDrivers && driversDataLatestEdfDriver
        ? moment(driversDataLatestEdfDriver.asOfDate).format(MOMENT_FORMATS.payload)
        : moment().format(MOMENT_FORMATS.payload);
    const fsoPd2 = hasPds ? driversData.pds.find(pd => moment(pd.date).format(MOMENT_FORMATS.payload) === edfDriver2Moment) : null;

    const fsoPd2Label =
      hasPds && fsoPd2 ? `${ttcPdLabel} (${moment(fsoPd2.date).format(MOMENT_FORMATS.display.monthYearLabel)})` : ttcPdLabel;
    const driverSeries: IPdDriverWithColor[] = [];

    // Build the driver data columns for the first "TTC PD" and "Financial Statement Change" difference
    if (pdsCount > 1 && edfDriversCount > 1) {
      const edfDriver1Moment = driversDataNextLatestEdfDriver
        ? moment(driversDataNextLatestEdfDriver.asOfDate).format(MOMENT_FORMATS.payload)
        : moment().format(MOMENT_FORMATS.payload);

      let fsoPd1 = driversData.pds.find(pd => moment(pd.date).format(MOMENT_FORMATS.payload) === edfDriver1Moment);

      fsoPd1 = Utils.isNullOrUndefined(fsoPd1) ? Utils.findLatestObjByDate(driversData.pds) : fsoPd1;
      fsoPd1 = Utils.isNullOrUndefined(fsoPd1) ? driversData.pds[pdsCount - 1] : fsoPd1;
      const pd1Label = `${ttcPdLabel} (${moment(fsoPd1.date).format(MOMENT_FORMATS.display.monthYearLabel)})`;
      const change = fsoPd2.pd - fsoPd1.pd;
      const label2 = 'Financial Statement Change';

      driverSeries.push({
        name: pd1Label,
        value: fsoPd1.pd,
        color: EdfxColors.lightGray4,
        grounded: true
      });
      driverSeries.push({
        name: label2,
        value: change,
        color: change < 0 ? EdfxColors.green : EdfxColors.red
      });
    }

    // Build the driver data columns for: Second TTC PD and Point in Time Adjustment
    const lastKnownPd = driversData?.lastKnownPd;
    const adjustment =
      !!driversData.quantitativePd && fsoPd2 ? driversData.quantitativePd - fsoPd2.pd : lastKnownPd && fsoPd2 ? lastKnownPd - fsoPd2.pd : 0;

    driverSeries.push({
      name: fsoPd2Label,
      value: fsoPd2?.pd ? fsoPd2.pd : 0,
      color: EdfxColors.lightGray4,
      grounded: true
    });
    driverSeries.push({
      name: pitAdjustmentLabel,
      value: adjustment,
      color: adjustment < 0 ? EdfxColors.green : EdfxColors.red
    });

    // Render remaining drivers
    driverSeries.push(...this.buildQuantitativeDiffAndFinalPDDrivers(driversData));

    return driverSeries;
  }

  /**
   * Helper method to build the remainder of the Driver Series data.
   * Handles logic for both scorecard and parent group support.
   * Conditionally renders quantitative PD (or final PiT PD), qualitative PD,
   * post parent support PD (or final PD).
   *
   * @param driversData - company data used to render the chart driversSeries
   * @private
   */
  private buildQuantitativeDiffAndFinalPDDrivers(driversData: IPdDriversChartData): IPdDriverWithColor[] {
    const lastKnownPd = driversData?.lastKnownPd;
    const driverSeries: IPdDriverWithColor[] = [];

    // Configure labels
    const labels = {
      finalPiTPdLabel: 'Final PiT PD',
      quantitativePd: 'Quantitative PD',
      overlay: 'Overlay',
      finalPd: 'Final PD',
      impliedParentSupport: 'Implied Parent Support',
      explicitParentSupport: 'Explicit Parent Support',
      pdPostSupport: 'PD Post Support'
    };
    const finalPiTPdLabelText =
      driversData.qualitativeOverlayPd || (!driversData.pgs && driversData.quantitativePd) ? labels.quantitativePd : labels.finalPiTPdLabel;
    const finalPiTPdLabel = driversData?.lastKnownPdDate
      ? `${finalPiTPdLabelText} (${moment(driversData.lastKnownPdDate).format(MOMENT_FORMATS.display.monthYearLabel)})`
      : finalPiTPdLabelText;

    // Check for quantitative PD and build Overlay and Parent Support columns
    if (!!driversData.quantitativePd) {
      const quantitativePdDifference = lastKnownPd - driversData.quantitativePd; // When either an overlay OR support is applied
      const overlayDifference = driversData.qualitativeOverlayPd // When either an overlay AND support is applied
        ? driversData.qualitativeOverlayPd - driversData.quantitativePd
        : null;
      const parentSupportDifference = driversData.qualitativeOverlayPd // When either an overlay AND support is applied
        ? lastKnownPd - driversData.qualitativeOverlayPd
        : null;

      // Final PiT PD / Quantitative PD
      driverSeries.push({
        name: finalPiTPdLabel,
        value: driversData.quantitativePd,
        color: EdfxColors.lightGray4,
        grounded: true
      });

      if (driversData.qualitativeOverlayPd) {
        // Qualitative overlay difference
        driverSeries.push({
          name: labels.overlay,
          value: overlayDifference,
          color: overlayDifference < 0 ? EdfxColors.green : EdfxColors.red
        });

        // Qualitative Overlay Pd
        driverSeries.push({
          name: labels.finalPd,
          value: driversData.qualitativeOverlayPd,
          color: EdfxColors.lightGray4,
          grounded: true
        });

        // Parent support difference
        driverSeries.push({
          name: labels.impliedParentSupport,
          value: parentSupportDifference,
          color: parentSupportDifference < 0 ? EdfxColors.green : EdfxColors.red
        });
      } else {
        // Scorecard Overlay / Parent Group Support adjustment
        driverSeries.push({
          name: driversData.pgs ? labels.explicitParentSupport : labels.overlay,
          value: quantitativePdDifference,
          color: quantitativePdDifference < 0 ? EdfxColors.green : EdfxColors.red
        });
      }
      // Last known Pd (Final PD / PD Post Support)
      driverSeries.push({
        name: driversData.pgs ? labels.pdPostSupport : labels.finalPd,
        value: lastKnownPd,
        color: EdfxColors.lightGray4,
        grounded: true
      });
    } else {
      // Last Known PD (Point in Time)
      driverSeries.push({
        name: finalPiTPdLabel,
        value: lastKnownPd,
        color: EdfxColors.lightGray4,
        grounded: true
      });
    }

    return driverSeries;
  }
}
