<div class="container" *ngIf="hasEntitlement && !loadingChart && !invalidEntityId && !isPublicModel && !isPeerDrivenEntity">
  <div class="card-title">{{localizationData.CARD_TITLE}}</div>
  <div class="card-description">{{localizationData.CARD_DESCRIPTION}}</div>
  <div class="override-message" *ngIf="!loadingChart && !hasData() && !displayInactiveMessage">
    <span>{{localizationData.NO_DATA_DISPLAY}}</span>
  </div>
  <ng-container *ngIf="!apiError && !isPublicModel && !invalidEntityId && hasData() && !displayInactiveMessage">
    <highcharts-chart (chartInstance)="setChart($event)" [(update)]="updateFlag" [Highcharts]="highcharts"
      [options]="options" [style.margin-top]="'25px'" [style.width]="'100%'" [style.height]="'100%'"
      style="display: block"></highcharts-chart>
  </ng-container>
  <div class="override-message" *ngIf="!loadingChart && displayInactiveMessage">
    <span>{{localizationData.INACTIVE_MESSAGE}}</span>
  </div>
</div>
<div fxLayout="row" fxLayoutAlign="space-around center">
  <div class="spinner" *ngIf="loadingChart && hasEntitlement"></div>
</div>
<ng-container *ngIf="hasEntitlement && isPeerDrivenEntity && !invalidEntityId">
  <p class="token-invalid-message">{{localizationData.PEER_DRIVEN_MESSAGE}}</p>
</ng-container>
<ng-container *ngIf="!loadingChart && !hasEntitlement && !apiError">
  <p>{{localizationData.ENTITLEMENT_ERROR}}</p>
</ng-container>
<ng-container *ngIf="!loadingChart && apiError">
  <p class="token-invalid-message">{{localizationData.TOKEN_INVALID}}</p>
</ng-container>
<ng-container *ngIf="hasEntitlement && isPublicModel && !invalidEntityId">
  <p class="token-invalid-message">{{localizationData.MODEL_UNAVAILABLE_ERROR}}</p>
</ng-container>
<ng-container *ngIf="hasEntitlement && !loadingChart && invalidEntityId">
  <p class="token-invalid-message">Entity id {{entityId}} does not exists.</p>
</ng-container>